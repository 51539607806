import { render, staticRenderFns } from "./company-bulk-operation-form.vue?vue&type=template&id=06d09ff8&scoped=true"
import script from "./company-bulk-operation-form.vue?vue&type=script&lang=js"
export * from "./company-bulk-operation-form.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d09ff8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {CompanyGroups: require('/codebuild/output/src2285020857/src/web/components/company/bulk-operations/company-groups.vue').default,DatetimeInput: require('/codebuild/output/src2285020857/src/web/components/datetime-input.vue').default,LoadingSpinner: require('/codebuild/output/src2285020857/src/web/components/loading-spinner.vue').default})
