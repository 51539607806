import { render, staticRenderFns } from "./company-bulk-operation-modal.vue?vue&type=template&id=346f510a&scoped=true"
import script from "./company-bulk-operation-modal.vue?vue&type=script&lang=js"
export * from "./company-bulk-operation-modal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "346f510a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2285020857/src/web/components/loading-spinner.vue').default,CompanyBulkOperationForm: require('/codebuild/output/src2285020857/src/web/components/company/company-bulk-operation-form.vue').default})
