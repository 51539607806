import { render, staticRenderFns } from "./edit-company-group-modal.vue?vue&type=template&id=dcc7ee58&scoped=true"
import script from "./edit-company-group-modal.vue?vue&type=script&lang=js"
export * from "./edit-company-group-modal.vue?vue&type=script&lang=js"
import style0 from "./edit-company-group-modal.vue?vue&type=style&index=0&id=dcc7ee58&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcc7ee58",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoadingSpinner: require('/codebuild/output/src2285020857/src/web/components/loading-spinner.vue').default,SaveButton: require('/codebuild/output/src2285020857/src/web/components/save-button.vue').default,TableActions: require('/codebuild/output/src2285020857/src/web/components/table-actions.vue').default,ConfirmModal: require('/codebuild/output/src2285020857/src/web/components/confirm-modal.vue').default})
