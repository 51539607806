import { render, staticRenderFns } from "./sheet-book.vue?vue&type=template&id=93b31816&scoped=true"
import script from "./sheet-book.vue?vue&type=script&lang=js"
export * from "./sheet-book.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93b31816",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FileUploadInput: require('/codebuild/output/src2285020857/src/web/components/file-upload-input.vue').default})
