
import fileDownload from "../../../mixins/file-download";
import modals from "../../../mixins/modals";
import DownloadScheduleModal from "../../interactions/download-schedule-modal";
export default {
    name: "CompanyAgendaCell",
    components: {DownloadScheduleModal},
    mixins: [fileDownload, modals, DownloadScheduleModal],
    props: {
        cell: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            event: null,
            interactionScheduleModal: null,
            excelExportQuery: {},
            showModal: false
        }
    },
    methods: {
        downloadInteractions() {
            this.showModal = true;
            this.$nextTick(() => {
                this.excelExportQuery = {
                    filters: {
                        company: this.cell.id,
                    },
                };
                this.$refs.downloadScheduleModal.reset();
                this.interactionScheduleModal = this.openModal(this.$refs.downloadScheduleModal);
            });
        },
        interactionScheduleDownloadTriggered() {
            this.closeModal(this.interactionScheduleModal);
            this.showModal = false;
        },
    },
}
